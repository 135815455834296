.btnRechazar,
.btnAceptar {
  border-radius: 5px;
  min-width: 225px;
}

.btnRechazar {
  border: solid 2px #b7b7b7 !important;
  background-color: #ece8e0 !important;
  color: black !important;
  margin-right: 10px !important;
  width: 100% !important;
  border: 0px !important;
  text-transform: uppercase;
}

.btnAceptar {
  background: #0061fe !important;
  color: white !important;
  width: 100% !important;
  margin-left: 10px !important;
  padding: 0px !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
  text-transform: uppercase;
}