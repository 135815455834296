@font-face {
  font-family: "SharpGrotesk_normal";
  src: url("./../public/assets/fuente/SharpGrotesk-Light20.otf") format("opentype");
}

@font-face {
  font-family: "SharpGrotesk_bold";
  src: url("./../public/assets/fuente/SharpGrotesk-Book20.otf") format("opentype");
}

@font-face {
  font-family: "AtlasGrotesk";
  src: url("./../public/assets/fuente/AtlasGrotesk-Thin.otf") format("opentype");
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.letraNavBar {
  font-family: "AtlasGrotesk";
}

.letraTitulos {
  font-family: "SharpGrotesk_bold";
}

.letraNormal {
  font-family: "SharpGrotesk_normal";
}

.MuiInput-root {
  border-bottom: 0px !important;
}

.MuiInput-root:hover {
  border-bottom: 0px !important;
}

.MuiInputBase-input:focus {
  outline: none !important;
  border-bottom: 0px !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }

.anchoMaximo {
  max-width: 1300px !important;
}

.icoMenu {
  margin: auto;
  color: #0061fe !important;
  stroke: #0061fe !important;
  font-size: 1.3rem !important;
  font-weight: bold !important;
  margin-top: -4px !important;
  margin-left: -3px !important;
}

.icoBotonesTrash {
  cursor: pointer !important;
  font-weight: 500;
  font-size: large;
  color: white !important;
  stroke: white !important;
}

.divBotonesTrash {
  background-color: #666666;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  margin-left: 3px;
}

.navbar-toggler {
  z-index: 99;
  background-color: #e8f3ff !important;
  border: 2px solid #e8f3ff !important;
  width: 38px !important;
  height: 38px !important;
  border: none !important;
  border-radius: 50px !important;
}

.icoQuestion {
  font-size: 1.4rem !important;
  position: absolute !important;
  bottom: 0% !important;
  right: 0% !important;
}

/*/////////////// Paginacion //////////////////////////////////////*/
.panelPaginationSection {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #dee2e6;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
}

.panelPaginationSection {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #dee2e6;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
}

.panelPaginationSection ul {
  margin-bottom: 0px;
}

.paginacionItem {
  width: 50px;
  padding: 10px;
  margin-left: 1px;
  margin-right: 1px;
  text-align: center;
  color: #007bff;
  background-color: white;
}

.paginacionActiva {
  background-color: #007bff;
  border-color: #007bff;
  color: white;

  text-decoration: none !important;
}

.paginacionItem a {
  color: inherit;
  text-decoration: none !important;
}

.paginacionItem a:hover {
  color: inherit !important;
}

.paginacionPrevio {
  width: fit-content;
  padding: 10px;
}

.paginacionSiguiente {
  width: fit-content;
  padding: 10px;
}

.paginacionDisabled {
  width: fit-content;
  padding: 10px;
  color: #6c757d;
  background-color: white;
  pointer-events: none;
}

.icoFlechas {
  color: #0061fe;
  font-size: 1.5rem !important;
}

/*/////////////// Encabezados //////////////////////////////////////*/
.lblTituloHoja {
  font-size: x-large;
}

.paddingEncabezados {
  padding-top: 40px;
  padding-bottom: 20px;
}

.paddingEncabezadosClientes {
  padding-top: 40px;
}

.paddingBottomEncabezados {
  margin-bottom: 10px;
}

/*/////////////// Tabla //////////////////////////////////////*/
.containerTabla {
  overflow: auto;
  width: 100%;
  height: auto;
}

@media (max-width: 980px) {
  .containerSubTabla {
    width: 980px !important;
  }
}

@media (min-width: 980px) {
  .containerSubTabla {
    width: 100%;
  }
}

@media (max-width: 1130px) {
  .containerTablaClientes {
    width: 1065px !important;
    margin-right: auto;
  }
}

@media (min-width: 1131px) {
  .containerTablaClientes, .containerTablaFisscalAncho {
    width: 85%;
    margin-right: auto;
  }
}

@media (max-width: 1380px) {
   .containerTablaFiscal {
    width: 1380px !important;
    margin-right: auto;
      overflow: auto;
  }
}


.containerTablaClientesBorder, .containerTablaFiscal {
  border: solid 2px #d2d2d2;
  border-radius: 8px;
}

.quitarBordes {
  border: solid 0px transparent !important;
}

.lblEncabezadosTablaRefacciones {
  font-size: small;
}

.lblEncabezadosTabla {
  font-size: medium;
}

.lblSubtabla {
  font-size: medium;
}

.lblSubtabla {
  word-wrap: break-word;
  font-size: 13px;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  vertical-align: middle;
}

.lblSubtablaBotones {
  text-align: right;
}

.lblSubtabla1cell {
  font-size: 13px;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.msgError {
  margin-top: 5px;
  font-size: medium;
  font-weight: 500;
  letter-spacing: 1px;
}

/*/////////////// Botones //////////////////////////////////////*/
.btnEliminar,
.btnEstandar,
.btnNuevo,
.btnActualizar,
.btnEditar,
.btnCancelModal,
.btnAceptarModal {
  border-radius: 5px;
}

.btnEliminar{
  font-weight: bold !important;
  font-size: 13px;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 25px;
}

.btnNuevo {
  border: none;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 25px;
  padding-right: 25px;
}

.btnActualizar {
  border: none;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 25px;
  padding-right: 25px;
}

.btnVerTabla {
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
  font-weight: bold !important;
}

.btnEditar {
  font-weight: bold !important;
  font-size: 13px;
  padding-left: 3px;
  padding-right: 8px;
  margin-left: 25px;
}

.btnCollapsar {
  border: none;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  font-size: 30px;
  font-weight: bold;
}

.btnMasInformacion {
  font-weight: bold !important;
  font-size: 13px;
  padding-left: 7px;
  padding-right: 7px;
  margin-left: 25px;
}

.btnAceptarModal {
  background: #0061fe !important;
  /*//*/
  color: white !important;
  /*//*/
  width: 100% !important;
  margin-left: 10px !important;
  padding: 0px !important;
  padding-right: 5px !important;
  padding-left: 5px !important;

  text-transform: uppercase;
}

.btnCancelModal {
  border: solid 2px #b7b7b7 !important;
  /*//*/
  background-color: #ece8e0 !important;
  /*//*/
  color: black !important;
  /*//*/
  margin-right: 10px !important;
  width: 100% !important;
  border: 0px !important;
  text-transform: uppercase;
}

/*/////////////// Inputs //////////////////////////////////////*/
.txtBuscadorAncho {
  position: relative;
  width: 273px;
}

.txtBuscador {
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 30px;
  padding-right: 24px;
}

.txtcanti {
  width: 85%;
  text-align: right !important;
  border: solid 1px black;
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 30x;
  padding-right: 5px;
}

.txtBuscador:focus,
input:focus {
  outline: none;
}

#Search::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

/*/////////////// Iconos //////////////////////////////////////*/
.icoBotones {
  font-size: large;
  font-weight: bold;
}

.icoLupa {
  position: absolute;
  left: 8px;
  top: 10px;
  font-size: large;
}

.icoInputBuscarClose {
  cursor: pointer;
  font-size: large;
  padding: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-top: -4px;
  margin-left: -26px;
}

/*/////////////// ComboBox //////////////////////////////////////*/
.selectBox {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.selectBox:after {
  width: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-top: 6px solid #5889fe;
  position: absolute;
  top: 40%;
  right: 5px;
  content: "";
  z-index: 98;
}

.selectBox select {
  border: 0;
  -webkit-appearance: none;
  position: relative;
  z-index: 99;
  background: none;
}

.lblTexto {
  padding-left: 6px;
  padding-right: 10px;
  font-size: medium;
}

.cbxTexto {
  width: 100%;
  border: none;
  padding-right: 22px;
}

.cbxTexto:focus {
  outline: none;
}

.divCombo {
  display: flex;
  width: 273px;
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}

/*/////////////// Modal //////////////////////////////////////*/
.icoCloseModal {
  position: absolute;
  font-size: x-large;
  top: 21px;
  right: 15px;
}

.comboModal {
  width: 97%;
  display: block;
  margin: auto;
}

.modalLblTitulo {
  margin-top: 5px;
  font-size: large;
  font-weight: bolder;
}

.divCuerpoModal {
  position: relative;
  width: 97%;
  display: block;
  margin: auto;
  border-radius: 5px;
  padding-bottom: 25px;
}

.divContenedorModal {
  padding: 25px;
  padding-bottom: 10px !important;
}

.lblSubTitulosModal {
  font-weight: 500;
  font-size: large;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.lblTextoModal {
  font-size: medium;
  width: 96%;
  display: block;
  margin: auto;
  margin-bottom: 18px;
  padding-left: 5px;
}

.modalDetallesCheck {
  width: fit-content;
  cursor: pointer;
  display: flex;
  margin: auto;
  align-items: center;
  font-size: medium !important;
}

.modalDetallesCheck input[type="checkbox"] {
  accent-color: #0177bf;
  width: 20px;
  height: 20px;
  margin-right: 10px !important;
}

.modalDetallesCheckContactoPrincipal {
  width: fit-content;
  cursor: pointer;
  display: flex;
  align-items: start;
  font-size: medium !important;
}

.modalDetallesCheckContactoPrincipal input[type="checkbox"] {
  accent-color: #0177bf;
  width: 20px;
  height: 20px;
  margin-right: 10px !important;
}

.centrarBotonFile {
  display: block;
  margin: auto;
  width: 99%;
}

/* style 2 */

.inputFile-2 + label {
  color: #0177bf;
  border: 2px solid black;
  border-radius: 20px;
  display: block !important;
  margin: auto !important;
}

.inputFile-2:focus + label,
.inputFile-2.has-focus + label,
.inputFile-2 + label:hover {
  color: #0177bf;
}

.js .inputFile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputFile + label {
  text-align: center;
  max-width: 360px;
  min-width: 280px;
  font-size: medium;

  /* 20px */
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
  /* 10px 20px */
}

.no-js .inputFile + label {
  display: none;
}

.inputFile:focus + label,
.inputFile.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputFile + label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  /* 4px */
  margin-right: 0.25em;
  /* 4px */
}

.inputFile-2::-webkit-file-upload-button {
  visibility: hidden;
}

.inputFile-2 {
  display: none;
}

.icoQuestionModal {
  font-size: 1.4rem;
  position: absolute;
  bottom: 0%;
  right: 0%;
}

.divBtnContenedorModal {
  display: flex;
  margin-bottom: 20px;
  margin-left: 10px;
  max-width: 400px !important;
  margin: auto;
  margin-top: 10px;
}

.tolTipFormat {
  border: solid 1px #808080 !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  max-width: 320px !important;
}

.trFondoActivo {
  background-color: #f7f5f2;
  border: solid 1px transparent !important;
  color: gray !important;
}

.trFondoInactivo {
  background-color: white;
  border: solid 1px transparent !important;
}

.trFondoActivoGreen {
  background-color: #D2FED2;
}

.trFondoActivoRed{
  background-color: #b76363 !important;
}

.trFondoInactivoGray {
  background-color: #a9a6a6;
  pointer-events: none;
}

.trFondoInactivoGray:focus {
  outline: none;
  box-shadow: none;
}

.rdp-day_selected{
  background-color:  "#0061fe" !important;
}

.btnSinBorde{
  border: none;
}

.inabilitado{
  background-color: #e5e5e5 !important;
}