.contenedorRow{
    flex-wrap: nowrap;
}

.contenedorInformaciónEmpleado {
    padding-right: 0px;
    width: 682px !important;
}

.tituloRancho {
    width: 1300px !important;
}

.contenedorDiasCalculos{
    overflow-x: auto;
    padding-left: 0px;
    width: 585px !important;
}

.txtinputcostosconerror{
    padding-left: 7px !important;
    width: 100px !important;
    border-radius: 5px;
    border: solid 1px #f50909 !important;
}