.rowElement {
  border-bottom: none !important;
}

.displayFlex {
  display: flex !important;
  border-bottom: none !important;
}

.nonePaddingTopBottom {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.minPaddingTopBottom {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.flexNoWrap {
  flex-wrap: nowrap;
}

.marginTopSeparator {
  margin-top: 15px;
}

.inputBasico {
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  background: none;
  width: 80%;
}

.contenedorFecha {
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 30px;
  padding-right: 24px;
  display: flex;
}

.iconoCalendario {
  position: relative;
  left: 8px;
  top: 5px;
  font-size: large;
}

.subcontenedorIconoCalendario {
  width: 10%;
  overflow: hidden;
  position: relative;
}

.contenedorEncabezado {
  border-bottom: 3px solid #2371fd;
}

.divComboRequisicion {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.selectBoxRequisición {
  width: 70%;
  overflow: hidden;
  position: relative;
}

.sinMargen {
  margin: 0px;
}

.spacePaddingBottom {
  padding-bottom: 10px;
}

.contenedorResumen {
  min-width: 640px;
  max-width: 640px;
  background: #f8f5f2;
}

/* Eliminar despues del merge */
.btnCollapsar {
  border: none;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  font-size: 30px;
  font-weight: bold;
}

.divBtnContenedorRequisicion {
  display: flex;
  margin-bottom: 20px;
  margin-left: 10px;
  max-width: 480px !important;
  margin: auto;
  margin-top: 10px;
}

.divMinWidth {
  min-width: 600px;
}

@media (max-width: 480px) {
  .btnAcciones {
    width: 100% !important;
  }
}

.anchoTablaProducto {
  min-width: 640px;
  max-width: 640px;
}