.inputLargeText, .inputLargeText:focus {
    background: white;
    border-radius: 10px;
    font-size: medium;
    width: 100%;
    height: 100px;
    display: block;
    margin: auto;
    margin-bottom: 18px;
    padding-left: 5px;
}

