.txtInputSimbolo{
    height: 23px !important;
    border: solid 1px #a7a7a7 !important;
    padding-left: 7px !important;
    padding-right: 7px !important;
    font-size: 13px !important;
}

.txtInputNumero{
    padding-left: 7px !important;
    width: 90px !important;
    border-radius: 5px;
    border: solid 1px #a7a7a7 !important;
}

.txtInputKgConError{
    padding-left: 7px !important;
    width: 90px !important;
    border-radius: 5px;
    border: solid 1px #f50909 !important;
}

.txtSimboloKgcConError{
    height: 23px !important;
    border: solid 1px #f50909 !important;
    border-left: solid 1px #a7a7a7 !important;
    padding-left: 7px !important;
    padding-right: 7px !important;
    font-size: 13px !important;
}

.txtSimboloPrecioConError{
    height: 23px !important;
    border-right: solid 1px #a7a7a7 !important;
    border: solid 1px #f50909 !important;
    padding-left: 7px !important;
    padding-right: 7px !important;
    font-size: 13px !important;
}

.txtInputPrecioConError{
    padding-left: 7px !important;
    width: 90px !important;
    border-radius: 5px;
    border: solid 1px #f50909 !important;
    border-left: solid 1px #a7a7a7 !important;
}

.txtInputNumeroConError{
    padding-left: 7px !important;
    width: 90px !important;
    border-radius: 5px;
    border: solid 1px #f50909 !important;
}

.contenedorTotales{
    padding-left:8px;
}

.seccionTotales{
    display: inline-flex;
    border-bottom: solid 1px #cac9c9 !important; ;
}

