.icoCloseModal {
  position: absolute;
  font-size: x-large;
  top: 21px;
  right: 15px;
}

.comboModal {
  width: 97%;
  display: block;
  margin: auto;
}

.modalLblTitulo {
  margin-top: 5px;
  font-size: large;
  font-weight: bolder;
}

.divCuerpoModal {
  position: relative;
  width: 97%;
  display: block;
  margin: auto;
  border-radius: 5px;
  padding-bottom: 25px;
}

.divContenedorModal {
  padding: 25px;
  padding-bottom: 10px !important;
}

.lblSubTitulosModal {
  font-weight: 500;
  font-size: large;
  margin-top: 0px !important;
  margin-bottom: 8px !important;
}

.lblTextoModal {
  font-size: medium;
  width: 96%;
  display: block;
  margin: auto;
  margin-bottom: 18px;
  padding-left: 5px;
}

.modalDetallesCheck {
  width: fit-content;
  cursor: pointer;
  display: flex;
  margin: auto;
  align-items: center;
  font-size: medium !important;
}

.modalDetallesCheck input[type="checkbox"] {
  accent-color: #0177bf;
  width: 20px;
  height: 20px;
  margin-right: 10px !important;
}

.icoQuestion {
  font-size: 1.4rem;
  position: absolute;
  bottom: 0%;
  right: 0%;
}

.divBtnContenedor {
  display: flex;
  margin-bottom: 20px;
  margin-left: 10px;
  max-width: 400px !important;
  margin: auto;
  margin-top: 10px
}

.tolTipFormat {
  border: solid 1px #808080 !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  max-width: 320px !important;
}

.divComboSelect {
  display: flex;
  max-width: 305px;
  min-width: 100%;
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.txtBuscadorCN {
  position: relative;
  max-width: 305px;
  min-width: 100%;
}

.txtBuscadorBCN {
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 305px;
  min-width: 100%;
}

.btnEliminarFila {
  border-radius: 5px;
  border: 0;
  border-color: #ff8484;
  background: #ff8484;
}

.containerSubTablaAu{
  min-width: 600px;
  max-width: 980px;
}


.botonesB{
  color: rgb(0, 97, 254);
  background-color: white;
  border: 2px solid rgb(0, 97, 254);
}

.trFondoIncompleto {
  background-color: #fab7d2;
}