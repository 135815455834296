.anchoMaximoConScroll {
  max-width: 1376px !important;
  padding-left: 0px;
  padding-right: 0px;
}

.icoBotonMenuCRC {
  cursor: pointer !important;
  font-weight: 500;
  font-size: large;
  color: #007bff !important;
  stroke: #007bff !important;
}

.btnMenuCRC {
  background-color: white;
  border: #007bff solid 1.5px;
  padding: 3px 10px;
  border-radius: 4px;
  margin-left: 10px;
}