.contenedorLogin {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.centrarDiv {
    display: table-cell;
    vertical-align: middle;
}

.divFormulario {
    position: relative;
    width: 95%;
    max-width: 400px;
    padding-left: 20px;
    padding-right: 20px;
    display: block;
    margin: auto;
}

.logoLogin {
    width: 90%;
    max-width: 420px;
    min-width: 300px;
    display: block;
    margin: auto;
}

.lblTituloLogin {
    font-size: larger;
    font-weight: 500;
    padding-bottom: 5px;
    letter-spacing: 1px;
}

.inputsLogin {
    height: 45px !important;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 8px;
    padding-left: 10px;
    letter-spacing: 1px;
    font-size: medium !important;
    width: 100%;
}

.btnLogin {
    font-size: large;
    font-weight: 600;
    width: 100% !important;
    letter-spacing: 2px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 35px;
    margin-bottom: 8px;
}

.spamContrasena {
    cursor: pointer;
    font-weight: 400;
    font-size: medium !important;
}