.icoAlmacenPrincipalActivo{
    font-size: x-large;
    color: green;
}
.icoAlmacenPrincipalInactivo{
  font-size: x-large;
  color: rgb(171, 171, 171);
}

.tolTipFormat {
    border: solid 1px #808080 !important;
    background-color: #ffffff !important;
    color: #000000 !important;
    max-width: 320px !important;
  }



  .descripcionAlmacen{
    display: flex;
    justify-content: space-between;
    border-top: 2px solid #007bff;
  }

  .icoQuestionDescripcion {
    font-size: 1.4rem;
    position: relative !important;
    bottom: 0%;
    right: 0%;
  }