.icoCloseModal {
    position: absolute;
    font-size: x-large;
    top: 21px;
    right: 15px;
  }
  
  .comboModal {
    width: 97%;
    display: block;
    margin: auto;
  }
  
  .modalLblTitulo {
    margin-top: 5px;
    font-size: large;
    font-weight: bolder;
  }
  
  .divCuerpoModal {
    position: relative;
    width: 97%;
    display: block;
    margin: auto;
    border-radius: 5px;
    padding-bottom: 25px;
  }
  
  .divContenedorModal {
    padding: 25px;
    padding-bottom: 10px !important;
  }
  
  .lblSubTitulosModal {
    font-weight: 500;
    font-size: large;
    margin-top: 0px !important;
    margin-bottom: 8px !important;
  }
  
  .lblTextoModal {
    font-size: medium;
    width: 96%;
    display: block;
    margin: auto;
    margin-bottom: 18px;
    padding-left: 5px;
  }
  
  .modalDetallesCheck {
    width: fit-content;
    cursor: pointer;
    display: flex;
    margin: auto;
    align-items: center;
    font-size: medium !important;
  }
  
  .modalDetallesCheck input[type="checkbox"] {
    accent-color: #0177bf;
    width: 20px;
    height: 20px;
    margin-right: 10px !important;
  }
  
  .icoQuestion {
    font-size: 1.4rem;
    position: absolute;
    bottom: 0%;
    right: 0%;
  }
  
  .divBtnContenedor {
    display: flex;
    margin-bottom: 20px;
    margin-left: 10px;
    max-width: 400px !important;
    margin: auto;
    margin-top: 10px
  }
  
  .tolTipFormat {
    border: solid 1px #808080 !important;
    background-color: #ffffff !important;
    color: #000000 !important;
    max-width: 320px !important;
  }
  
  .btnNuevoAccesos {
    width: 100%;
  }

  .btnNuevoProducto {
    border: none;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 0px;
    padding-right: 0px;
    width: 200px;
}

.btnAgregarDestino{
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 25px;
  padding-right: 25px;
  width: 100%;
  border-radius: 5px;
}

.checkModal {
  width: fit-content;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  font-size: medium !important;
}

.checkModal input[type="checkbox"] {
  accent-color: #0177bf;
  width: 20px !important;
  height: 20px !important;
  margin-right: 10px !important;
}

.divContenedorCkeckModal {
  padding: 25px;
  padding-bottom: 10px !important;
  display: inline-block;
  max-width: 100%;
  width: 100%;
}

.especialLabel{
  color: gray;
}

.divComboSelectModal {
  display: flex;
  width: 100%;
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.txtBuscadorModal{
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
}

.txtBuscadorContenedor {
  position: relative;
  width: 100%;
}

.contenedorControlesModal{
  width: 100%;
}

.tablaMaxHeight{
  max-height: 410px;
}

.labelPresupuestado {
  font-size: 12px;
}