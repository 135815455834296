.anchoMaximoTabCultivo {
    max-width: 1510px !important;
}
.divComboFullWith {
    display: flex;
    width: 100%;
    border-radius: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.tablaActividad tr {
    border-color: gray;
    border-style: solid;
    border-width: 1px !important;
}
.contenedorNowrap{
  flex-wrap: nowrap;
}

.contenedorWrap{
  flex-wrap: wrap;
}
.contenedorTPrincipal{
    padding-right: 0px;
    min-width: 402px;
}
.contenedorMeses{
    overflow-x: auto;
    padding-left: 0px;
}

  .celdasMeses {
    min-width: 125px !important;
    width: 125px !important;
  }

  .celdasMeses input {
    padding: 0px 0px 0px 0px !important;
    padding-top:  0px !important;
    padding-bottom:  0px !important;
  }

  .celdaACtividad {
    min-width: 125px !important;
    width: 125px !important;
  }

  .celdaACtividad input {
    padding: 0px 0px 0px 0px !important;
    padding-top:  0px !important;
    padding-bottom:  0px !important;
  }
  .anchoMaximoActividades{
    max-width: 1412px !important;
  }

  .contenedorResumenPA{
    max-width: 1100px;
    background: #f7f7f7;
}

.encabezadoResumen{
  white-space: nowrap;
}

.paddingR{
  padding-right: 10px;
}

.msgInvalidacion {
  margin-top: 5px;
  font-size: small;
  font-weight: 500;
  letter-spacing: 1px;
}