/*Modal estatus requisición*/
.divTituloModal {
  position: relative;
  width: 97%;
  display: block;
  margin: auto;
  border-radius: 5px;
  padding-top: 25px;
}

.divModalEliminar {
  position: relative;
  width: 97%;
  display: block;
  margin: auto;
  border-radius: 5px;
}

.listaOpcionesEstatus {
  font-size: medium;
  font-weight: bold;

}

/**/
.areaTextoModal {
  font-size: medium;
  width: 96%;
  display: block;
  margin: auto;
  padding: 10px 15px;
  margin-bottom: 18px;
  border-radius: 20px;
  border-width: 1px;
  border-color: #6c757d;
  resize: none;
}

.nombreRequisicionModal {
  font-weight: 500;
  font-size: large;
  margin-top: 0px !important;
  margin-bottom: 10px !important;
}