.icoRegistroPrincipal{
    font-size: x-large;
    color: green;
}

.anchoMaximoCatCompras{
    max-width: 1600px !important;
}

.contenedorFechaCompras {
    border-radius: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 6px;
    padding-right: 6px;
    display: flex;
    align-items: center;
    max-width: 520px;
  }

  .subcontenedorIconoCalendarioCompras {
    width: 8%;
    min-width: 28px;
    overflow: hidden;
    position: relative;
  }

.labelFecha{
    min-width: 172px;
}

.inputDate{
    max-width: 125px;
    font-size: 15px;
}