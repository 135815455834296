.checkAccesoAlsistema{
    width: fit-content;
    cursor: pointer;
    display: flex;
    margin: initial;
    align-items: center;
    font-size: medium !important;
    font-weight: bold;
}

.checkAccesoAlsistema input[type="checkbox"] {
    accent-color: #0177bf;
    width: 20px;
    height: 20px;
    margin-right: 10px !important;
  }
  .lblTextoNombreUsuario {
    font-size: medium;
    width: 96%;
    display: block;
    margin: auto;
    margin-bottom: 5px;
    padding-left: 5px;
  }

  .btnGenerarUsuario{
    background-color: #ece8e0 !important;
    padding: 2px;
    width: 6%;
    height: 20px;
    cursor: pointer;
  }