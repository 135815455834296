.txtFechaAnio {
  position: relative;
  width: 200px;
}

.btnDateInput {
  border-radius: 5px;
  border: none;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 25px;
  padding-right: 25px;
}