.principalCalendario{
  display: flex;
  width: 300px;
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  height: 35px;
}

.subcontenedorIconoCalendario{
  width: 10%;
  overflow: hidden;
  position: relative;
}

.subcontenedorEtiquetaFecha{
  width: 25%;
  overflow: hidden;
  position: relative;
}
.subcontenedorFecha{
  width: 35%;
  overflow: hidden;
  position: relative;
}
.subcontenedorBorrarFecha{
  position: absolute;
  right: 0px;
}

.iconoCalendario{
  position: absolute;
  left: 8px;
  top: 5px;
  font-size: large;
}

.contenedorCalendario{
  width: 100%;
  position: absolute;
  top: 35px;
  background: white;
  font-size: 13px;
  z-index: 100;
}

.icoInputCerrarCalendario {
  cursor: pointer;
  font-size: large;
  padding: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-top: 3px;
  margin-right: 5px;
  margin-bottom: 5px;
  float: right;
}
