.lblEstatusDisponible,
.lblEstatusCompra1,
.lblEstatusAutorizada,
.lblEstatusRechazada {
  font-size: large;
  padding-left: 20px;
  padding-right: 20px;
}

.lblEstatusDisponible {
  color: black;
}

.lblEstatusCompra1 {
  color: #0061fe;
}

.lblEstatusAutorizada {
  color: #128f35;
}

.lblEstatusRechazada {
  color: #ff8484;
}

.lblEstatusHecho {
  background-color: #128f35;
  color: white;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

.anchoTablaCotProductos {
  min-width: 1000px !important;
  max-width: 1200px !important;
}

.tituloTabla {
  margin-left: 15px;
  margin-bottom: 0px;
}