.icoCheck{
  font-size: x-large;
  color: #007bff;
}

.btnRechazarProd {
  background-color: #e87175 !important;
  color: black !important;
  margin-right: 10px !important;
  border: 0px !important;
  text-transform: uppercase;
  font-family: "SharpGrotesk_normal";
}

.btnAceptarProd {
  background-color: #0061fe !important;
  color: white !important;
  margin-left: 10px !important;
  border: 0px !important;
  text-transform: uppercase;
  font-family: "SharpGrotesk_normal";
}

.stlMsgRechazo {
  background-color: #ec989a !important;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
}

.stlEstatusCompra1 {
  color: #007bff;
}

.stlEstatusCompra2 {
  color: #128f35;
}


.rowElementAutoCot {
  border-bottom: none !important;
}

.nonePaddingTopBottomAutoCot {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.minPaddingTopBottomAutoCot {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.flexNoWrapAutoCot {
  flex-wrap: nowrap;
}

.marginTopSeparatorAutoCot {
  margin-top: 15px;
}

.contenedorEncabezadoAutoCot {
  border-bottom: 3px solid #2371fd;
}

.contenedorResumenAutoCot {
  min-width: 640px;
  max-width: 640px;
  background: #f8f5f2;
}

.btnCollapsarAutoCot {
  border: none;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  font-size: 30px;
  font-weight: bold;
}

.divMinWidthAutoCot {
  min-width: 600px;
}
