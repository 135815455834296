.progresoSpinner{
    position: relative;
    z-index: 2000;
    width: 100%;
    height: 170px;;   
}

.progresoSpinnerFull{
    position: fixed;
    left: 0px;
    top:0px;
    bottom:0px;
    right: 0px;
    z-index: 3000;    
}

.animacionSpinner{
    background: #000000cc;
    width: 100%;
    height: 100%;
    display: flex;
}

.body{
    padding-right: 0px !important;
  }