.estiloRowPhone {
    border-right: solid 2px #0061fe;
  }
  .comboTelefono {
    min-width: 80px;
    max-width: 90px;
    margin-left: 3px;
    border: none;
    outline: none;
    scroll-behavior: smooth;
    background-color: transparent !important;
  }
  .comboTelefono::before,
  .comboTelefono:hover {
    border: none;
    outline: none;
    scroll-behavior: smooth;
    background-color: transparent !important;
  }
  .anchoDivTelefono {
    margin-bottom: 18px !important;
    width: 96%;
    margin: auto;
  }
  .phoneSelection {
    color: #0061fe !important;
    font-size: medium !important;
    font-weight: bolder !important;
  }
  
  .inputFileP + label {
    max-width: 400px !important;
    padding: 0.425rem 0rem !important;
  }