.contenedorNotificacion{
    background: #f2f6ff;
    border-radius: 10px;
    margin-bottom: 8px;
    height: 130px;
}

  .mensajeNotifiacion {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .tituloNotificacion{
    margin-left: 10px;
    margin-right: 10px;
  }

  .cuerpoNotifiacion{
    max-height: 550px;
    overflow-y: auto;
  }