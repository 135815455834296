.anchoMaximo2 {
    max-width: 1920px !important;
  }
  
.paddingEncabezados1 {
    padding-top: 10px;
    padding-bottom: 0px;
}

.paddingEncabezados2 {
  padding-top: 0px;
  padding-bottom: 10px;
}

.lblTexto2 {
  padding-left: 6px;
  width: 100%;
  padding-bottom: 0;
  font-size: small;
}

.divCombo2 {
  display: flex;
  width: 100%;
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}