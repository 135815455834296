.divSubMenu {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  width: 100% !important;
  background-color: transparent;
  color: #000;
}

.divSubMenu:hover {
  background-color: #d3e3fd;
  color: #000;
}

.divConSubMenu {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  width: 100% !important;
  background-color: transparent;
  color: #000;
}

.divConSubMenu:hover {
  background-color: #ffffff;
  color: #000;
}

.divConSubMenu {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  width: 100% !important;
  background-color: transparent;
  color: #000;
}

.DivMenu {
  width: 100%;
}

.lblSubMenuItems {
  width: 100% !important;
  font-size: medium;
  color: #000 !important;
  text-decoration: none !important;
}

.lblConSubMenuItems {
  width: 100% !important;
  font-size: medium;
  color: #000 !important;
  text-decoration: none !important;
}
.navSalir {
  text-align: center;
  width: 110px !important;
}
.navItem {
  text-align: center;
  width: 110px !important;
}

.lblNavItem {
  letter-spacing: 0.5px;
  font-weight: normal !important;
  font-size: medium;
}

.icoArrowNavbar {
  color: #0061fe;
  position: absolute;
  top: 1px;
  font-size: 1.5rem !important;
  right: -15px;
}

.estiloSeleccionado {
  background-color: #ede9e3 !important;
  border-radius: 5px;
}

.navItem:hover {
  background-color: #ede9e3 !important;
  border-radius: 5px;
}
.navItemSalir:hover {
  background-color: #ede9e3 !important;
  border-radius: 5px;
}
.styleNavItem {
  background-color: transparent !important;
  text-decoration: none !important;
  color: black !important;
  border: none !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.divContieneTitulos{
  position: relative;
  width: 100% !important;
}

.divSubContieneTitulos{
  position: relative;
  width: 100% !important;
}

.divSubContieneTitulos:hover {
  background-color: #ffffff;
  color: #000;
}

.icoNotifications{
  width: 25px;
  height: 25px;
  color: #0061fe;
  cursor: pointer;
}

.iconNotificationMovil{
  background: none !important;
  margin-right: 5px;
}