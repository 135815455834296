.anchoMaximoTabCultivo {
  max-width: 1710px !important;
}

.divCombo {
  display: flex;
  width: 260px;
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.btnBase {
  font-weight: bold !important;
  font-size: 13px;
  padding-left: 3px;
  padding-right: 8px;
  float: left;
  width: 75px;
}

.iconoAcceso {
  display: flex;
  justify-content: center;
}

.btnRestablecer {
  font-weight: bold !important;
  font-size: 13px;
  padding-left: 3px;
  padding-right: 8px;
  margin-left: 25px;
  width: 124px;
}

.itemVerticalCenter {
  vertical-align: middle;
}


.txtinputcostos {
  padding-left: 7px !important;
  width: 100px !important;
  border-radius: 5px;
  border: solid 1px #a7a7a7 !important;
}

.bodersinputopagos {
  text-align: center;
  border-left: solid 1px #a7a7a7;
  border-right: solid 1px #a7a7a7;
}

.lbljoract {
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
  width: 180px;
  font-size: medium;
  border-radius: 3px;
  background-color: #06c30d;
}

.lbljorfin {
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
  width: 200px;
  font-size: medium;
  border-radius: 3px;
  background-color: #ff7d7d;
}

.labelFecha {
  min-width: 172px;
}

.icobuscardatoss {
  background-color: #0061fe;
  margin-top: -4px;
  margin-bottom: -4px;
  margin-right: -7px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-bottom: 9px;
  padding-top: 5px;
  padding-left: 9px;
  padding-right: 9px;
  font-weight: bold;
  stroke: white !important;
  color: white !important;

}