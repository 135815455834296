.txtinputsimbolo{
    height: 23px !important;
    border: solid 1px #a7a7a7 !important;
    padding-left: 7px !important;
    padding-right: 7px !important;
    font-size: 13px !important;
}

.txtInputNumero{
    padding-left: 7px !important;
    width: 90px !important;
    border-radius: 5px;
    border: solid 1px #a7a7a7 !important;
}

.contenedorTotales{
    padding-left:8px;
}

.seccionTotales{
    display: inline-flex;
    border-bottom: solid 1px #cac9c9 !important; ;
}

.contenedorCorridaTotales{
    min-width: 640px;
    max-width: 640px;
    background: #f8f5f2;
   
    height: auto;
}

.separadorDeContenedor{
    margin-top: 25px !important;
}
.containerTabla2{
    width: 100% !important;
    overflow: auto;
}

