.anchoMaximoTabCultivo {
  max-width: 1710px !important;
}

.divComboas {
  display: flex;
  width: 180px;
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.btnBase {
  font-weight: bold !important;
  font-size: 13px;
  padding-left: 3px;
  padding-right: 8px;
  float: left;
  width: 75px;
}

.iconoAcceso {
  display: flex;
  justify-content: center;
}

.btnRestablecer {
  font-weight: bold !important;
  font-size: 13px;
  padding-left: 3px;
  padding-right: 8px;
  margin-left: 25px;
  width: 124px;
}

.itemVerticalCenter {
  vertical-align: middle;
}

.icocheckboxnew {
  font-size: x-large;
  text-align: center;
  margin: auto;
  width: fit-content;
  display: block;
  font-weight: bold;

}

.icoeditarclass{
  font-size: 16px;
  background-color: #ececec;
  border-radius: 10px;
}
