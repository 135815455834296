.divTituloDetalles{
    position: relative;
    width: 97%;
    display: block;
    margin: auto;
    padding-right: 25px;
}

.btnRestablecerModal{
    font-weight: bold !important;
    background-color: white !important;
    font-size: 13px;
    padding-left: 3px;
    padding-right: 8px;
    width: 124px;
}

.divBotonDescargar {
    background-color: rgb(0, 97, 254);
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    margin-left: 3px;
}

.divBotonEliminar {
    background-color: red;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    margin-left: 3px;
}