.rowElement{
    border-bottom: none !important;
}

.displayFlex{
    display: flex !important;
    border-bottom: none !important;
}

.nonePaddingTopBottom{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.minPaddingTopBottom{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.flexNoWrap{
    flex-wrap: nowrap;
}

.marginTopSeparator{
    margin-top: 15px;
}

.inputBasico{
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
    background: none;
    width: 80%;
}

.contenedorFechaR{
    border-radius: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 0px;
    padding-right: 24px;
    display: flex;
}

.subcontenedorIconoCalendarioReq {
    width: 5%;
    min-width: 28px;
    overflow: hidden;
    position: relative;
  }

.iconoCalendario{
    position: relative;
    left: 8px;
    top: 5px;
    font-size: large;
  }

  .subcontenedorIconoCalendario{
    width: 10%;
    overflow: hidden;
    position: relative;
  }

  .contenedorEncabezado{
    border-bottom: 3px solid #2371fd;
  }

  .divComboRequisicion{
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .selectBoxRequisición {
    width: 70%;
    overflow: hidden;
    position: relative;
}

.sinMargen{
    margin: 0px;
}

.spacePaddingBottom{
    padding-bottom: 10px;
}
.contenedorResumenNR{
    max-width: 920px;
    background: #f7f7f7;
}

.centerText{
    text-align: center;
}

.tablaTodosProductos{
    background: #d3fdcf;
    height: 50px;
    align-items: center;
    text-align: center;
}

.seccionAviso {
    border-radius: 5px;
    border: 0;
    border-color: #ff8484;
    background: #ff8484;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .checkPlanAnual {
    cursor: pointer;
    display: flex;
    margin: 10px;
    align-items: center;
    font-size: medium !important;
    
  }
  

  .checkPlanAnual input[type="checkbox"] {
    accent-color: #0177bf;
    width: 20px;
    height: 20px;
    margin-right: 10px !important;
    float: left;
  }

  .customBorderRadius{
    border-radius: 5px;
    padding: 2px;
    margin-left: 12px;
  }

  .customBorderRadius2{
    border-radius: 5px;
    padding: 2px;
  }

  .icoBuscar{
    font-size: large;
  }