.contentSpaceBetween{
    justify-content: space-between;
}

.contenedor{
    border-radius: 10px;
}

.containerInformacionGeneral{
    margin-top: 5px;
}
/** --------------------------------------------- */
.lblEncabezadosTablaProv {
    font-size: small;
}

.tablaUnica {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.icoRegistroPrincipal{
    font-size: x-large;
    color: green;
}

.icoEditarTablaModal{
    font-size: large;
    float: right;
}
