.principalCalendario{
    display: flex;
    width: 300px;
    border-radius: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.subcontenedorIconoCalendario{
    width: 15%;
    overflow: hidden;
    position: relative;
}

.subcontenedorEtiquetaFecha{
    width: 32%;
    overflow: hidden;
    position: relative;
}

.iconoCalendario{
    position: absolute;
    left: 8px;
    top: 0 !important;
    font-size: large;
}

.contenedorCalendario{
    width: 100%;
    position: absolute;
    top: 35px;
    background: white;
    font-size: 13px;
}