.borderNone{
    border: none !important;
}

.btnEliminarModalDetalles{
    border-color: #ff6262;
    background: #ff6262;
    color: black !important;
}

.iconbtneliminar{
    color: black !important;
}