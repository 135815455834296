.rowElement {
  border-bottom: none !important;
}

.displayFlex {
  display: flex !important;
  border-bottom: none !important;
}

.nonePaddingTopBottom {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.minPaddingTopBottom {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.flexNoWrap {
  flex-wrap: nowrap;
}

.marginTopSeparator {
  margin-top: 15px;
}

.inputBasico {
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  background: none;
  width: 80%;
}

.contenedorFecha {
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 30px;
  padding-right: 24px;
  display: flex;
}

.iconoCalendario {
  position: relative;
  left: 8px;
  top: 5px;
  font-size: large;
}

.subcontenedorIconoCalendario {
  width: 10%;
  overflow: hidden;
  position: relative;
}

.contenedorEncabezado {
  border-bottom: 3px solid #2371fd;
}

.divComboRequisicion {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.selectBoxRequisición {
  width: 70%;
  overflow: hidden;
  position: relative;
}

.sinMargen {
  margin: 0px;
}

.spacePaddingBottom {
  padding-bottom: 10px;
}

.contenedorResumen {
  min-width: 1020px;
  max-width: 90%;
  background: #f8f5f2;
}

/* Eliminar despues del merge */
.btnCollapsar {
  border: none;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  font-size: 30px;
  font-weight: bold;
}

.divBtnContenedorRequisicion {
  display: flex;
  margin-bottom: 20px;
  margin-left: 10px;
  max-width: 480px !important;
  margin: auto;
  margin-top: 10px;
}

.divMinWidth {
  min-width: 600px;
}

@media (max-width: 480px) {
  .btnAcciones {
    width: 100% !important;
  }
}

.anchoTablaProducto {
  min-width: 90%;
  max-width: 100%;
}


.btnAzulCotizaciones{
  background: #0061fe !important;
  color: white !important;
  width: 100% !important;
  padding: 0px !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.btnBlancoCotizaciones{
  background: #ffffff !important;
  color: #0061fe !important;
  border-color: #0061fe;
  width: 100% !important;
  padding: 0px !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.btnResCotizaciones{
  background: none;
  color: #000000 !important;
  border-color: 0px !important;
  width: 100% !important;
  padding: 0px !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.btnEditarCotizaciones{
  background: #0061fe !important;
    color: white !important;
    width: 100% !important;
    border-radius: 5px;
    border: 0px !important;
}

.tarjetasCotizacion{
  height: 300px;
}

.tablaProductoCotizaciones{
  background: white;
}


.btnEditarCotizaciones2{
  background: #0061fe !important;
    color: white !important;
    width: 80% !important;
    border-radius: 5px;
    border: 0px !important;
    height: 30px;
}

.autorizado{
  color: #2dc723;
}

.btnComprarCotizaciones{
  background: #2dc723 !important;
    color: white !important;
    width: 80% !important;
    border-radius: 5px;
    border: 0px !important;
    height: 30px;
}

.btncomprasCotizaciones{
  background: #2dc723 !important;
    color: white !important;
    width: 100% !important;
    border-radius: 5px;
    border: 0px !important;
}

.sinPendientes{
  color: #0061fe;
  font-weight: bold;
}

.noborrar{
  color: #fd0a1e;
  font-weight: bold;
}

.btnRestaurarCotizaciones{
  color: #000000 !important;
  font-weight: bold !important;
  border: 0px !important;
  background: none !important;
  width: 100% !important;
    border-radius: 5px;
    text-decoration:underline;
}

.sustituidoFila{
  background: #b9b9b9;
}

.sustitutosFila{
  background: #a1c2ff;
}

.letrasAzules{
  word-wrap: break-word;
  font-size: 13px;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  vertical-align: middle;
  font-family: "SharpGrotesk_normal";
  color: #0061fe;
  font-weight: bold;
}

.letrasVerdes{
  word-wrap: break-word;
  font-size: 13px;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  vertical-align: middle;
  font-family: "SharpGrotesk_normal";
  color: #2dc723;
  font-weight: bold;
}

.letrasRojas{
  word-wrap: break-word;
  font-size: 13px;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  vertical-align: middle;
  font-family: "SharpGrotesk_normal";
  color: #fe0000;
  font-weight: bold;
}