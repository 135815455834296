.btnAccesos{
 border: rgb(0, 97, 254) 2px solid;
}

.lblSinDatos {
    color: red !important;
}

.datosCorrectos {
    font-size: x-large;
    color: green;
}

.anchoMaximoExtendido {
    max-width: 1820px !important;
}

.btnBase {
    font-weight: bold !important;
    font-size: 13px;
    padding-left: 3px;
    padding-right: 8px;
    float: left;
    /*width: 75px;*/
}

.btnNuevoDoc {
    background-color: #666666;
    padding: 3px 0px;
    border: #666666;
    border-radius: 5px;
    color: #ffffff;
    font-weight: bold;
}

.celdaDosElementos {
    display: flex;
}

.iconoNotificacion {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    padding: 4px 5px;
}

.tooltipClickeable {
    color: #222222 !important;
    background-color: #d3e3fd !important;
    padding-left: 4px;
}

.stooltipClickeable place-top :after {
    border-top-color: #d3e3fd !important;
    border-top-style: solid !important;
    border-top-width: 6px !important;
}

.btnDisponible,
.btnEsperaAutorizacion,
.btnAutorizado,
.btnRechazado,
.btnCancelado,
.btnEditarOT,
.botonNotasOT,
.btnAsignadoOT {
  /*  border: solid 2px #bebdbc !important;*/
    background-color: white !important;
}


.btnEditarOT,.btnDisponibleOT, .btnAsignadoOT, .btnCompletadoOT{
    background-color: white !important;
}

.btnDisponibleOT,
.btnEsperaAutorizacion {
    color: #0061fe
}

.btnAutorizado {
    color: #00d953
}

.btnRechazado {
    color: red
}

.btnAsignadoOT {
    color: rgb(255, 123, 0)
}

.btnCancelado {
    color: #8b8a89
}

.btnEditarOT {
    color: #000000
}

.botonNotasOT{
    color: #8b8a89
}

.btnCompletado, .btnCompletadoOT {
    font-weight: bold !important;
    font-size: 13px;
    padding-left: 3px;
    padding-right: 8px;
    float: left;
    color: white !important;
    border: solid 2px #3cb43c !important;
    background-color: #3cb43c !important;
}

.btnCotizacion {
    color: white !important;
    background-color: #3cb43c;
    font-size: 13px;
    padding: 2px 8px;
    margin-left: 4px;
    float: left;
    border-radius: 5px;
    border: solid 2px #3cb43c;
}

.lblPendiente,
.lblEnProceso,
.lblFinalizado,
.lblDetenido {
    font-weight: bold !important;
    font-size: 13px;
}

.lblPendiente {
    color: black;
}

.lblEnProceso {
    color: #0061fe;
}

.lblFinalizado {
    color: #00ce4f
}

.lblDetenido {
    color: red
}

.contenedorFechaOperacion {
    border-radius: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 6px;
    padding-right: 6px;
    display: flex;
    align-items: center;
    min-width: 550px;
    max-width: 560px;
}

.subContenedorFechaOperacion {
    /*border-radius: 5px;*/
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 6px;
    padding-right: 6px;
    display: flex;
    align-items: center;
    border-bottom: solid 2px #bebdbc;
    border-top: solid 2px #bebdbc;
    border-left: solid 2px #bebdbc;
    min-width: 510px;
    max-width: 510px;
}

.contenedorFechaOperacionLupa {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    align-items: center;
    min-width: 42px;
    max-width: 42px;
    height: 100%;
    max-height: 34px;
    min-height: 34px;
    margin: 0px;
}

.subcontenedorIconoLupa {
    overflow: hidden;
    position: relative;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 100%;
    height: 100%;
    max-height: 34px;
    min-height: 34px;
    margin: 0px;
    padding: 0px;
    background-color: #0061fe;
    border: 2px solid #0061fe;
}

.icoBotonLupa {
    font-size: large;
    font-weight: bold;
    padding: auto;
    margin: auto;
    color: white;
    width: 24px;
}