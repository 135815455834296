.borderNone{
    border: none !important;
}

.lblSelect{
    width: 50%;
}
.selectOption{
    width: 60%;
}

.selectOptionL{
    width: 100%;
}

.btnMes{
    width: 100%;
    height: 60px;

}

.btnMesActivo, .btnMesActivo:hover {
    color: #0061fe;
    border: 3px solid #2d7dde;
    background-color: #ecf6eb;
}

.btnMesInactivo, .btnMesInactivo:hover {
    color: black;
    border: 3px solid #9d9d9d;
    background-color: white;
}

.sinPaddingLeftRight{
    padding-left: 0px;
    padding-right: 6px;
}
.btnFloatRight{
    float: right;
}