.checkBoxPlanOT {
  cursor: pointer;
  display: flex;
  margin: auto;
  font-size: medium !important;
}

.checkBoxPlanOT input[type="checkbox"] {
  accent-color: #0177bf;
  width: 20px;
  height: 20px;
  margin-right: 2px !important;
}

.labelFechaOrden {
  min-width: 90px;
}

.areaTextoModalOT {
  font-size: medium;
  width: 100%;
  display: block;
  margin: 0px;
  padding: 10px 15px;
  border-radius: 5px;
  border-width: 1px;
  border-color: #6c757d;
  resize: none;
}

.fechaMes {
  position: relative;
  width: 320px;
}

@media (min-width: 1012px) {
  .espacioMsg {
    padding: 8.5px;
  }
}

@media (max-width: 1012px) {
  .espacioMsg {
    padding: 0px;
    margin: 0px;
  }
}

.inputOrdenTrabajo {
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  background: none;
  width: 80%;
}

.divBtnContenedorRequisicionOT {
  display: flex;
  margin-bottom: 20px;
  margin-left: 10px;
  max-width: 480px !important;
  margin: auto;
  margin-top: 10px;
}