.areaTextoModal {
  font-size: medium;
  width: 96%;
  display: block;
  margin: auto;
  padding: 10px 15px;
  margin-bottom: 18px;
  border-radius: 5px;
  border-width: 1px;
  border-color: #6c757d;
  resize: none;
}

.inTextoModal {
  font-size: medium;
  width: 94%;
  display: block;
  margin: auto;
  border-radius: 5px;
}

.letraNormal {
  font-family: "SharpGrotesk_normal";
}

.labelSelector {
  margin-top: 0px !important;
  margin-bottom: 8px !important;
  padding-top: 0px !important;
}