.lblEncabezadosTablaProv {
    font-size: small;
}

.tablaUnica {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.icoRegistroPrincipal{
    font-size: x-large;
    color: green;
}

.icoEditarTablaModal{
    font-size: large;
    float: right;
}

.trFondoIncompleto {
    background-color: #fab7d2;
}

.trFondoDetener {
    background-color: #656565;
}





.autocomplete-wrapper {
    width: 100%;
    position: relative;
    display: inline-block;
  }
  
  .autocomplete-wrapper > div {
    width: 100%;
  }
  
  .autocomplete-wrapper input {
    border: 1px solid #cecece;
    width: 100%;
  }
  
  .autocomplete-wrapper input:focus {
    border-color: #646464;
    box-shadow: none;
    outline: none;
  }
  
  .autocomplete-wrapper .dropdown {
    width: 100%;
    padding: 0;
    text-align: left;
    max-height: 280px;
    overflow: hidden;
    overflow-y: auto;
    background-color: #ffffff;
    border: 1px solid #4c4c4c;
    border-top: none;
  }
  
  .autocomplete-wrapper .item  {
    display: block;
    cursor: pointer;
  }
  
  .autocomplete-wrapper .item.selected-item {
    background-color: #8c8c8c;
    color: #FAFBFC;
  }
  
  .autocomplete-wrapper .item:hover {
    background-color: #868686;
    color: #FAFBFC;
  }

  .inputSubtabla {
    border: 1px solid #cecece;
    width: 100%;
  }

  .btnEliminarFila {
    border-radius: 5px;
    border: 0;
    border-color: #ff8484;
    background: #ff8484;
  }