.lblSinDatos {
    color: red !important;
}

.datosCorrectos {
    font-size: x-large;
    color: green;
}

.anchoMaximoExtendido {
    max-width: 1820px !important;
}

.btnBase {
    font-weight: bold !important;
    font-size: 13px;
    padding-left: 3px;
    padding-right: 8px;
    float: left;
    /*width: 75px;*/
}

.btnNuevoDoc {
    background-color: #666666;
    padding: 3px 0px;
    border: #666666;
    border-radius: 5px;
    color: #ffffff;
    font-weight: bold;
}

.celdaDosElementos {
    display: flex;
}

.iconoNotificacion {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    padding: 4px 5px;
}

.tooltipClickeable {
    color: #222222 !important;
    background-color: #d3e3fd !important;
    padding-left: 4px;
}

.stooltipClickeable place-top :after {
    border-top-color: #d3e3fd !important;
    border-top-style: solid !important;
    border-top-width: 6px !important;
}

.btnDisponible,
.btnEsperaAutorizacion,
.btnAutorizado,
.btnRechazado,
.btnCancelado {
    border: solid 2px #bebdbc !important;
    background-color: white !important;
}

.btnDisponible,
.btnEsperaAutorizacion {
    color: #0061fe
}

.btnEsperaAutorizacionreqs{
    border: solid 2px rgb(0, 97, 254) !important;/*#bebdbc !important;*/
    background-color: rgb(0, 97, 254); /*rgb(255, 196, 0) !important;*/
    color: white !important;
}

.btnAutorizado {
    color: #00d953
}

.btnRechazado {
    color: red
}

.btnCancelado {
    color: #8b8a89
}

.btnCompletado {
    font-weight: bold !important;
    font-size: 13px;
    padding-left: 3px;
    padding-right: 8px;
    float: left;
    color: white !important;
    border: solid 2px #3cb43c !important;
    background-color: #3cb43c !important;
}

.btnCotizacion {
    color: white !important;
    background-color: #3cb43c;
    font-size: 13px;
    padding: 2px 8px;
    margin-left: 4px;
    float: left;
    border-radius: 5px;
    border: solid 2px #3cb43c;
}

.lblPendiente,
.lblEnProceso,
.lblFinalizado,
.lblDetenido {
    font-weight: bold !important;
    font-size: 13px;
}

.lblPendiente {
    color: black;
}

.lblEnProceso {
    color: #0061fe;
}

.lblFinalizado {
    color: #00ce4f
}

.lblDetenido {
    color: red
}

.anchoMaximoConScrollCRC {
    max-width: 1376px !important;
    padding-left: 0px;
    padding-right: 0px;
}