.InputHora{
    border-top: none !important;
    background-color: #fafbfd !important;
    color: black !important;
    width: 99% !important;
    font-size: medium !important;
    margin: auto !important;
    margin-bottom: 18px !important;
    padding-left: 5px !important;
    margin-bottom: 35px !important;
}

.divBtnContenedorBotonesReloj {
    display: flex;
    margin: auto;
}
